<template>
  <div class="imageBackground">
    <div class="contentContainer">
      <custom-header />
      <div class="mainContainer">
        <div class="titleContainer bg-primary">
          <p class="titleText">{{ $t("labels.weeklyChallenge") }}</p>
        </div>
        <div class="subContainer p-0">
          <div class="weeklyChallengeContainer">
            <p class="secondaryTitleText">{{ weeklyChallenge.title }}</p>
            <p class="desc">{{ getWeeklyChallengeHtmlText() }}</p>
          </div>
          <div class="p-10 wp-100 flex-1">
            <textarea
              v-model="content"
              class="textContent"
              :placeholder="$t('labels.writeDownTextHere')"
            />
          </div>
        </div>
        <div>
          <button class="submitBtn btn" @click="share()">
            {{ $t("labels.share") }}
          </button>
          <button
            class="loadImg btn"
            v-bind:style="
              image == null
                ? { backgroundColor: '#298cff' }
                : { backgroundColor: 'darkgray' }
            "
            @click="chooseFiles()"
          >
            <p 
              class="loadBtnTitle">
              {{ image == null ? $t("labels.uploadImage") : image.name }}
            </p>
            <img :src="camera" class="camera" />
          </button>
          <input
            id="fileUpload"
            type="file"
            accept="image/jpeg, image/png"
            @change="doFile"
            hidden
          />
        </div>
        <div class="topic1ImageContainer mt-30">
          <img class="topicImage" :src="topicImage" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import CustomHeader from "../components/CustomHeader.vue"
export default {
  name: "WeeklyChallengePage",
  components: {
    CustomHeader,
  },
  data() {
    return {
      topicImage: require("../assets/image/png/jigsaw.png"),
      camera: require("../assets/image/svg/camera_white.svg"),
      content: "",
      image: null,
    }
  },

  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/")
    },
    getWeeklyChallengeHtmlText() {
      return this.removeHtmlTag(this.weeklyChallenge.html_text)
    },
    removeHtmlTag(html) {
      if (!html) return ""
      var rex = /(<([^>]+)>)/gi
      return html.replace(rex, "")
    },
    share() {
      if (!this.content) {
        this.content = this.image
      }
      this.$store.dispatch("main/replyWeeklyChallengeAttempt", {
        entry: this.content,
        image: this.image,
      })
    },
    chooseFiles() {
      document.getElementById("fileUpload").click()
    },
    doFile(e) {
      const file = e.target.files[0]
      this.image = file
      //this.convertBase64(file)
    },
    convertBase64(file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.image = e.target.result
      }
      reader.readAsDataURL(file)
    },
  },
  computed: mapState({
    weeklyChallenge: (state) => state.main.weeklyChallenge,
    isLoading: (state) => state.root.isLoading,
  }),
  mounted() {
    this.$store.dispatch("main/getWeeklyChallenge")
  },
  watch: {
    isLoading: {
      handler(newVal) {
        if (!newVal) {
          this.image = null
          this.content = ""
        }
      },
    },
  },
}
</script>

<style scoped>
.weeklyChallengeContainer {
  border-bottom: 1px solid lightgray;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.secondaryTitleText {
  font-size: 18px;
  color: var(--textColor);
  text-align: center;
  padding: 0px 10px;
}
.desc {
  color: var(--textColor);
  font-size: 15px;
  /* width: 100%; */
  text-align: center;
  padding: 10px;
}
.textContent {
  width: 100%;
  border: none;
  background-color: transparent;
  height: 100px;
  resize: none;
  overflow-wrap: break-word;
  flex-wrap: wrap;
  font-size: 15px;
  color: var(--textColor);
  padding: 10px;
}
.loadImg {
  padding: 10px 20px;
  align-self: flex-start;
  border-radius: 10px;
  margin-top: 20px;
  font-size: 18px;
  color: white;
  display: flex;
  height: 46px;
  max-width: 45%;
}
.loadBtnTitle {
  white-space: nowrap;
  text-overflow: ellipsis; 
  overflow: hidden;
}
.submitBtn {
  background-color: red;
  padding: 10px 20px;
  align-self: flex-start;
  border-radius: 10px;
  margin-top: 20px;
  font-size: 18px;
  color: white;
  float: right;
}
.topic1ImageContainer {
  width: 100%;
  padding-top: 52.25%;
  position: relative;
  overflow: hidden;
}
.topicImage {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
}
.camera {
  position: relative;
  top: -2px;
  margin-left: 8px;
}
</style>
